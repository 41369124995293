import React from 'react';
import ReactDOM from 'react-dom/client';
import { configureStore, history } from 'store';
import { Device } from '@capacitor/device';
import { createSetEnvAction, createSetPlatformAction } from 'store/ducks/env';
import { LanguageCode, Platform } from 'interfaces/api';
import { getPlatform } from 'utils/device';
import Root from 'containers/Root/Root';
import * as Sentry from '@sentry/capacitor';
import { init as sentryReactInit } from '@sentry/react';
import axios from 'axios';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { SentryErrorBoundary } from 'containers';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const store = configureStore();

(async () => {

  const platform = await getPlatform();

  store.dispatch(createSetPlatformAction(platform));

  const config = (await axios.get('/config.json', { responseType: 'json' })).data;

  if (import.meta.env.MODE === 'production' && config.DISABLE_SENTRY !== '1') {
    Sentry.init({
      dsn: 'https://57f503e2552f405f887928e7c494eca0@sentry.labuniq.com/2',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      environment: import.meta.env.MODE,
      release: 'onlinebefunde@' + APP_VERSION,
    }, sentryReactInit);

    Sentry.setContext('config', config);
    Sentry.setTag('target', config.TARGET);
  }

  store.dispatch(createSetEnvAction(config));

  const render = async () => {
    const deviceLocale = await Device.getLanguageCode();
    const locale = [deviceLocale.value].filter(l => (config.I18N_LOCALES_SHORT || 'en,de').split(',').indexOf(l.split('-')[0]) > -1)[0] as LanguageCode;

    const container = document.getElementById('root');
    const root = ReactDOM.createRoot(container);

    root.render(
      <SentryErrorBoundary>
        <Root
          store={store}
          history={history}
          locale={locale}
        />
      </SentryErrorBoundary>,
    );
  };

  if (platform === Platform.WEB) {
    return render();
  } else {
    await render();
    document.getElementById('root').classList.add('is-cordova');
  }

})();
