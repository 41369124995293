import React, { useCallback, useMemo } from 'react';
import { ListItem } from 'components/List';
import {
  AggregatedProfileProperties,
  AggregatedRequirementFlags, AggregatedSuperRequirementProperties,
  OrderRequirementsFilter,
  OrderRequirementsSpecialFilterId,
  OrderWizardGetSettingsResponse,
  OrderWizardRequirement,
  RequirementAggregationType,
} from 'interfaces/api';
import { TooltipPlacement } from 'antd/es/tooltip';
import { RequirementPopOver } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver';
import { PopoverProps } from 'components/Popover';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import dayjs from 'dayjs';
import { find } from 'lodash';
import { OrderAggregationConfig } from 'modules/orders/interfaces';
import { OrderAggregations } from 'modules/orders/utils';
import { faBug, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { Color, Message } from 'interfaces';
import messages from 'messages';
import { faCrown, faQuestionCircle, faStar } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'providers';
import { getLongDateFormat } from 'utils/helpers';

export const useGetPopover = () => {

  const { wizardSettings } = useOfficeDoctorContext();

  const { orderWizardDisablePopovers } = wizardSettings?.preferences || {};

  return (item: ListItem<OrderWizardRequirement>, detail?: boolean, basket?: boolean, options?: Omit<PopoverProps, 'children'>) => {

    const { meta: requirement } = item;

    if (orderWizardDisablePopovers
      && !requirement.flags.includes(AggregatedRequirementFlags.IsProfile)
      && !requirement.flags.includes(AggregatedRequirementFlags.IsSuperRequirement)) {
      return undefined;
    }

    return {
      arrow: true,
      placement: 'right' as TooltipPlacement,
      content: <RequirementPopOver requirement={requirement} detail={detail} basket={basket}/>,
      destroyTooltipOnHide: true,
      mouseEnterDelay: 0.3,
      ...options,
      // visible: requirement.id === 46,
    };
  };
};

export const getBadgeForRequirement = (requirement: OrderWizardRequirement) => {

  const { analyses } = requirement;

  if (!analyses) {
    return undefined;
  }

  const noExpansion = analyses.length === 1 && (
    requirement.shortName === analyses[0].shortName
    || requirement.longName === analyses[0].longName
  );

  return noExpansion || analyses.length <= 1 || requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect) ? undefined : analyses.length;

};

/**
 * Returns either the freeText or the freeTextDate of the given requirement
 * @param requirement
 * @param format
 */
export const freeTextByType = (requirement: OrderWizardRequirement, format?: boolean) => {
  if (requirement?.freeTextAsDate) {
    if (format) {
      const freeTextMoment = dayjs(requirement?.freeTextDate).isValid() ? dayjs(requirement?.freeTextDate) : dayjs();
      return freeTextMoment.format(getLongDateFormat());
    }

    return requirement?.freeTextDate?.trim();
  }

  return requirement?.freeText?.trim();
};

export const isProfile = (entity: OrderWizardRequirement | AggregatedProfileProperties): entity is AggregatedProfileProperties => {
  return entity.flags?.includes(AggregatedRequirementFlags.IsProfile);
};

export const isSuperRequirement = (entity: OrderWizardRequirement | AggregatedProfileProperties): entity is AggregatedSuperRequirementProperties => {
  return entity.flags?.includes(AggregatedRequirementFlags.IsSuperRequirement);
};

const getSpecialFilterMessage = (id: OrderRequirementsSpecialFilterId) => ({
  [OrderRequirementsSpecialFilterId.Unassigned]: messages.orders.requirementAggregations.unassigned,
  [OrderRequirementsSpecialFilterId.Profiles]: messages.orders.requirementAggregations.profiles,
  [OrderRequirementsSpecialFilterId.Favorites]: messages.orders.requirementAggregations.favorites,
  [OrderRequirementsSpecialFilterId.TopRequests]: messages.orders.requirementAggregations.topRequests,
  [OrderRequirementsSpecialFilterId.Microbiological]: messages.orders.requirementAggregations.microbiological,
}[id]);

const getSpecialFilterIcon = (id: OrderRequirementsSpecialFilterId) => ({
  [OrderRequirementsSpecialFilterId.Unassigned]: faQuestionCircle,
  [OrderRequirementsSpecialFilterId.Profiles]: faLayerGroup,
  [OrderRequirementsSpecialFilterId.Favorites]: faStar,
  [OrderRequirementsSpecialFilterId.TopRequests]: faCrown,
  [OrderRequirementsSpecialFilterId.Microbiological]: faBug,
}[id]);

export const useGetFilterConfig = () => {
  const { translate } = useIntl();

  return useCallback((filter: OrderRequirementsFilter, settings: OrderWizardGetSettingsResponse) => {

    filter = find(settings.filters, filter) || filter;

    if (filter.type === RequirementAggregationType.OrderForm) {
      const filterForm = find(settings.forms, { id: filter.id });
      if (filterForm) {
        const { costUnit, name } = filterForm;
        filter.name = `${translate(messages.orders.wizard.formCostUnitFormat, { costUnit, name })}` ?? '';
      }
    }

    const name: Message = filter.name || translate(getSpecialFilterMessage(filter.id));

    const config: OrderAggregationConfig = filter.type !== undefined
      ? find(OrderAggregations, { type: filter.type })
      : {
        icon: getSpecialFilterIcon(filter.id),
        color: Color.Blue,
        type: filter.type,
      };

    return { name, ...config };
  }, [translate]);
};

export const useInvoiceTo = () => {

  const { translate } = useIntl();

  const optionLabels = messages.orders.additionalFields;

  const options = useMemo(() => ([
    { value: 'patient', label: translate(optionLabels.invoiceToOptions.patient) },
    { value: 'arzt', label: translate(optionLabels.invoiceToOptions.doctor) },
  ]), [translate]);

  const getSelectedLabel = useCallback((invoiceTo: string) => {
    return find(options, { value: invoiceTo })?.label;
  }, [options]);

  return {
    options,
    getSelectedLabel,
  };

};
