import React, { useCallback, useEffect, useState } from 'react';
import constate from 'constate';
import { useDispatch } from 'react-redux';
import { useApi, useBiometrics, useIntl, useLogger, useStorage } from 'providers';
import { createLogoutAction, createResolveLegacyAction, createSetAvatarAction, createSetLidAction, createSetUserAction } from 'modules/auth/store/ducks/auth';
import { useErrorModal } from 'components';
import messages from 'messages';
import { AuthenticationCreateTokenRequest, LogLevel } from 'interfaces/api';
import { getStore } from 'store';
import { useAsync } from 'react-use';
import { useNetworkProvider } from 'providers/NetworkCheckProvider';
import { useMaintenanceProvider } from 'providers/MaintenanceProvider';
import { CapacitorCookies } from '@capacitor/core';
import { useEnv } from 'store/components/injectEnv';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';

const authContext = () => {

  const auth = getStore().getState().auth;

  const dispatch = useDispatch();

  const { getToken, setIdentifier, getIdentifier, getFaceIdLoginRequested } = useStorage();
  const biometrics = useBiometrics();
  const { translate } = useIntl();
  const { isWeb } = useEnv();

  const { isConnected } = useNetworkProvider();
  const { isMaintenance } = useMaintenanceProvider();

  const { authentication: { login: apiLogin, exchangeToken, logout: apiLogout, getUserDetails }, profile: { getUserAvatar } } = useApi();

  const errorModal = useErrorModal();

  const login = useCallback(async (data: AuthenticationCreateTokenRequest) => {

    dispatch(createSetUserAction(await apiLogin(data)));

    if (await getIdentifier() !== data.kennung) {
      await biometrics.disable();
      await setIdentifier(data.kennung);
    }

    if (!await getFaceIdLoginRequested()) {
      await biometrics.showFaceIdRequest();
    }

  }, [apiLogin, getIdentifier, biometrics.disable, biometrics.showFaceIdRequest, setIdentifier]);

  const [loadingAutoLogin, setLoadingAutoLogin] = useState(false);

  const autoLogin = useCallback(async () => {

    if (!auth.user) {

      const deviceToken = await getToken();

      if (deviceToken) {
        await biometrics.show({});
        setLoadingAutoLogin(true);
        try {
          dispatch(createSetUserAction(await exchangeToken({ token: deviceToken })));
        } catch (e) {
          biometrics.disable();
          errorModal({
            title: translate(messages.auth.login.autoLoginError.title),
            content: translate(messages.auth.login.autoLoginError.content),
          });
        }
        setLoadingAutoLogin(false);
      }
    }
  }, [getToken, biometrics.show, biometrics.disable, exchangeToken, setLoadingAutoLogin]);

  const logout = useCallback(async () => {
    if (!isWeb) {
      await CapacitorCookies.clearAllCookies();
    }
    return apiLogout().finally(() => dispatch(createLogoutAction()));
  }, [apiLogout]);

  const setLid = useCallback((lid: number) => dispatch(createSetLidAction(lid)), []);

  const resolveLegacy = useCallback(() => dispatch(createResolveLegacyAction()), []);

  const setAvatar = useCallback((avatar: File) => dispatch(createSetAvatarAction(avatar)), []);

  useEffect(() => {
    if (auth.user?.id) {
      getUserAvatar({ id: auth.user.id }, { responseType: 'blob' }).then((result) => {
        if (result?.size > 0) {
          setAvatar(result);
        }
      });
    }
  }, [auth.user?.id]);

  const reloadUserDetails = useCallback(async () => {
    if (auth.user?.id && isConnected && !isMaintenance) {
      dispatch(createSetUserAction(await getUserDetails()));
    }
  }, [getUserDetails, isConnected, isMaintenance, auth.user]);

  // pause and resume handling

  App.addListener('pause', async () => {
    await SplashScreen.show();
  });

  App.addListener('resume', async () => {
    await reloadUserDetails();
    await SplashScreen.hide();
  });

  const { legacy, wasAutoLoggedOut, legacyRejected, avatar } = auth;

  return {
    legacy,
    wasAutoLoggedOut,
    login,
    autoLogin,
    loadingAutoLogin,
    logout,
    setLid,
    resolveLegacy,
    legacyRejected,
    reloadUserDetails,
    setAvatar,
    avatar,
  };

};

export const useAuthUser = () => {
  return getStore().getState().auth.user;
};

export const useAuthLid = () => {
  return getStore().getState().auth.lid;
};

const [InternalAuthProvider, useAuth] = constate(
  authContext,
);

export {
  useAuth,
};

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const dispatch = useDispatch();

  const { authentication: { getUserDetails } } = useApi();
  const { setLocale, locale } = useIntl();
  const user = useAuthUser();

  const { legacy, lid } = getStore().getState().auth;

  const { setLogLevel } = useLogger();

  useEffect(() => {
    const userLocale = user?.locale ?? undefined;
    if (userLocale && userLocale !== locale) {
      setLocale(userLocale);
    }
  }, [user?.locale]);

  useEffect(() => {
    if (user?.debugEnabled) {
      setLogLevel(LogLevel.Debug);
    }
  }, [user?.debugEnabled]);

  const auth = useAsync(async () => {
    if (legacy) {
      return undefined;
    }
    const details = await getUserDetails();
    dispatch(createSetUserAction(details));
  }, [lid]);

  if (auth.loading) {
    return null;
  }

  if (auth.error) {
    throw auth.error;
  }

  return (
    <InternalAuthProvider>
      {children}
    </InternalAuthProvider>
  );
};
