import { useCallback } from 'react';
import { AggregatedProfileProperties, AggregatedSuperRequirementProperties, OrderChangeAction, OrderWizardRequirement } from 'interfaces/api';
import { find, includes, map } from 'lodash';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/BasketProvider';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { isProfile, isSuperRequirement } from 'modules/orders/containers/OrderWizard/utils';
import { useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useUpsertBasket';

export const useToggleBasket = () => {

  const { wizardSettings } = useOfficeDoctorContext();
  const { setAllOrSelectedOrders, appendLog, setOrdersMapped } = useOrdersContext();
  const {
    poolRequirementsAllInBasket,
    poolRequirementsPartialInBasket,
    profileRequirementsAllInBasket,
    profileRequirementsPartialInBasket,
    subRequirementsAllInBasket,
    subRequirementsPartialInBasket,
  } = useBasketContext();

  const upsertBasket = useUpsertBasket();

  // remove requirement from basket
  const removeRequirement = useCallback((requirement: OrderWizardRequirement) => {
    setAllOrSelectedOrders(order => ({ ...order, requirements: order.requirements.filter(r => r.id !== requirement.id) }));
    appendLog(OrderChangeAction.RemoveRequirement, requirement);
  }, [appendLog, setAllOrSelectedOrders]);

  // remove profile from basket
  const removeProfileFromBasket = useCallback((requirement: AggregatedProfileProperties) => {
    appendLog(OrderChangeAction.RemoveProfileRequirement, requirement);
    setOrdersMapped(order => ({ ...order, requirements: order.requirements.filter(r => r.profileId !== requirement.id) }));
  }, [appendLog, setOrdersMapped]);

  // remove super requirement from basket
  const removeSuperRequirementFromBasket = useCallback((requirement: AggregatedSuperRequirementProperties) => {
    const superRequirement = find(wizardSettings.superRequirements, { entityId: requirement.entityId });
    const subRequirementIDs = map(superRequirement?.requirements, r => r.id);
    setOrdersMapped(order => ({
      ...order,
      requirements: order.requirements.filter(r => (!includes(subRequirementIDs, r.id) && (!r.superRequirement || r.entityId !== superRequirement.entityId)),
      ),
    }));
    appendLog(OrderChangeAction.RemoveRequirement, requirement);
  }, [appendLog, wizardSettings?.superRequirements, setOrdersMapped]);

  /**
   * toggle profile
   */
  const toggleProfile = useCallback((requirement: AggregatedProfileProperties, fromBasket?: boolean) => {
    if (profileRequirementsAllInBasket(requirement) || (profileRequirementsPartialInBasket(requirement) && fromBasket)) {
      removeProfileFromBasket(requirement);
    } else {
      upsertBasket(requirement);
    }
  }, [profileRequirementsAllInBasket, profileRequirementsPartialInBasket, removeProfileFromBasket, upsertBasket]);

  /**
   * toggle super requirement
   */
  const toggleSuperRequirement = useCallback((requirement: AggregatedSuperRequirementProperties, fromBasket?: boolean) => {
    if (subRequirementsAllInBasket(requirement) || (subRequirementsPartialInBasket(requirement) && fromBasket)) {
      removeSuperRequirementFromBasket(requirement);
    } else {
      upsertBasket(requirement);
    }
  }, [subRequirementsAllInBasket, subRequirementsPartialInBasket, removeSuperRequirementFromBasket, upsertBasket]);

  /**
   * toggle requirement
   */
  const toggleRequirement = useCallback((requirement: OrderWizardRequirement, fromBasket?: boolean) => {
    if (poolRequirementsAllInBasket(requirement) || (poolRequirementsPartialInBasket(requirement) && fromBasket)) {
      removeRequirement(requirement);
    } else {
      upsertBasket(requirement);
    }
  }, [poolRequirementsAllInBasket, poolRequirementsPartialInBasket, removeRequirement, upsertBasket]);

  /**
   * main toggle basket function
   */
  return useCallback((requirement: OrderWizardRequirement | AggregatedProfileProperties, fromBasket?: boolean) => {
    if (isProfile(requirement)) {
      toggleProfile(requirement, fromBasket);
    } else if (isSuperRequirement(requirement)) {
      toggleSuperRequirement(requirement, fromBasket);
    } else {
      toggleRequirement(requirement, fromBasket);
    }
  }, [toggleProfile, toggleSuperRequirement, toggleRequirement]);

};
