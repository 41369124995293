import React, { useMemo, useRef } from 'react';
import { filter, find, omit } from 'lodash';
import { Control, FormRenderProps, ModalControl, ModalFormControl, ModalFormControlProps } from 'components';
import { Feature, OrderWizardLocalization, WriteableOrderProperties } from 'interfaces/api';
import messages from 'messages';
import { useMandatoryFields, useOfficeDoctorContext, useOrderRulesContext, useOrdersContext } from 'modules/orders/providers';
import { faAddressBook, faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { PatientScanner } from 'modules/orders/containers/OrderWizard/components/PatientScanner';
import { PatientsSelect } from 'modules/orders/containers/OrderWizard/components/PatientsSelect';
import { arrayify } from 'utils/helpers';
import { PatientForm } from './PatientForm';
import { useLogger } from 'providers';
import { useGuard } from 'containers';
import { mapCostUnit, useSwitzerlandBags } from 'modules/orders/utils';

export const PatientFormModalControl = (props: { index?: number } & Partial<ModalFormControlProps<WriteableOrderProperties, any>>) => {

  const {
    orders,
    setOrders,
    selectedOrderIndex,
    initialOrder,
    setShowCostUnitSelect,
    readonly,
    currentOrder,
  } = useOrdersContext();

  const { getMandatoryPatientFieldsForOrder } = useMandatoryFields();
  const { wizardSettings, officeDoctor: { localisation } } = useOfficeDoctorContext();

  const index = props.index ?? selectedOrderIndex ?? 0;

  const guard = useGuard();

  const { mapSwitzerlandInsuranceValues } = useSwitzerlandBags();

  const logger = useLogger('PatientFormModalControl');

  const formBindings = useRef<FormRenderProps<WriteableOrderProperties>>(undefined);
  const setFormBindings = (args: FormRenderProps<WriteableOrderProperties>) => {
    formBindings.current = args;
  };

  const { getBasketErrorsByName } = useOrderRulesContext();
  const invalidFieldErrors = useMemo(() => find(getBasketErrorsByName('InputError'), { idx: selectedOrderIndex || 0 })?.errors, [getBasketErrorsByName, selectedOrderIndex]);

  const predefinedSwitzerlandInsuranceValues = guard({ feature: Feature.SwitzerlandInsuranceMapping }, () => true);

  return (
    <ModalFormControl
      {...props}
      tooltip={{ open: false }}
      modal={{
        ...props.modal,
        withTabs: true,
        title: messages.orders.patients.edit,
        footer: (!readonly) && [guard({ feature: Feature.WizardScanPatient }, () => (
          <ModalControl
            key={'scan'}
            icon={faQrcode}
            label={messages.orders.patients.scanPatient}
            button={{}}
            modal={{
              fullHeight: true,
              footer: null,
              children: ({ hide }) => (
                <PatientScanner
                  onPatientScanned={(orderProperties) => {
                    formBindings.current?.onChange(orderProperties);
                    hide();
                  }}
                />
              ),
            }}
          />
        )), (
          <ModalControl
            key={'select'}
            icon={faAddressBook}
            label={messages.orders.patients.selectPatients}
            button={{}}
            modal={{
              withTabs: true,
              fullHeight: true,
              okText: messages.orders.patients.select,
              title: messages.orders.patients.selectPatients,
              footer: null,
              children: ({ hide }) => (
                <PatientsSelect
                  index={index}
                  onSelect={(selected) => {
                    selected = arrayify(selected);

                    if (localisation === OrderWizardLocalization.AUT) {
                      selected = selected.map(s => omit(s, 'costUnit'));
                    } else if (localisation === OrderWizardLocalization.CHE && predefinedSwitzerlandInsuranceValues) {
                      selected = selected.map(mapSwitzerlandInsuranceValues);
                    }

                    formBindings.current.onChange({ patient: selected[0], costUnit: selected[0].costUnit });

                    if (selected.length > 1) {
                      const availablePatients = filter(orders.map(({ patient: { apgid } }) => apgid > 0 ? apgid : null));
                      const newPatients = selected.filter(s => !availablePatients.includes(s.apgid));

                      logger.debug('PatientsSelect onSelect set order', [...orders, ...newPatients.slice(1).map(patient => ({ patient, costUnit: patient.costUnit }))]);

                      setOrders(prevOrders => [...prevOrders, ...newPatients.slice(1).map(patient => ({ patient }))]);

                      window.setTimeout(formBindings.current.submit, 50);

                    }

                    hide();

                  }}
                />
              ),
            }}
          />
        ), (
          <Control
            button={{
              type: 'primary',
            }}
            key={'submit'}
            label={messages.general.applyChanges}
            onClick={() => formBindings.current.submit()}
          />
        )],
      }}
      form={{
        error: invalidFieldErrors?.[0],
        disabled: readonly,
        required: getMandatoryPatientFieldsForOrder(orders[index] || initialOrder),
        initialValue: { ...(orders[index] || initialOrder) },
        request: (order) => {
          logger.debug('form submit', order);
          setOrders((prevOrders) => {
            prevOrders[index] = { ...prevOrders[index], ...order };
            return prevOrders;
          });
        },
        children: (args) => {
          setFormBindings(args);
          return <PatientForm {...args}/>;
        },
        onSuccess: (values) => {
          if (currentOrder?.costUnit === undefined && wizardSettings?.preferences?.orderWizardShowCostUnitSelect) {
            if (!values?.patient?.costUnit || !wizardSettings?.costUnits.includes(mapCostUnit(values?.patient?.costUnit))) {
              setShowCostUnitSelect(true);
            }
          }
        },
      }}
    />
  );

};
