import React, { useRef } from 'react';
import { Control, ControlProps } from 'components/Control';
import { Input, InputRef } from 'antd';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useOrdersContext } from 'modules/orders/providers';
import { useConfirmModal } from 'components';
import { useIntl } from 'providers';

type EditBarcodeControlProps = Omit<ControlProps, 'onClick'> & {
  tnr: string;
  onDone?: (tnr: string) => void;
};

const labels = messages.orders.wizard.barcodes;

export const EditBarcodeControl = (props: EditBarcodeControlProps) => {

  const { tnr, onDone } = props;

  const { setOrderProperties } = useOrdersContext();

  const { translate } = useIntl();
  const confirmModal = useConfirmModal();

  const inputRef = useRef<InputRef>();

  const perform = () => {
    let editedTnr = tnr;
    confirmModal({
      title: translate(labels.edit.title),
      content: (
        <div>
          <Input
            minLength={100}
            autoFocus
            ref={(ref) => {
              inputRef.current = ref;
              window.setTimeout(() => ref?.focus(), 250);
            }}
            defaultValue={tnr}
            onKeyPress={(event) => {
              if (!/[0-9a-zA-Z]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              editedTnr = e.target.value;
            }}
            placeholder={translate(labels.edit.placeholder)}
          />
        </div>
      ),
      onOk: async () => {
        setOrderProperties({ tnr: editedTnr });
        onDone?.(editedTnr);
      },
    });
  };

  return (
    <Control
      icon={faPencil}
      onClick={perform}
    />
  );
};
