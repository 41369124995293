import React, { CSSProperties } from 'react';
import * as pathToRegexp from 'path-to-regexp';
import {
  AggregatedProfileProperties,
  AggregatedProfileType,
  Interval,
  LogLevel,
  OrderChangeAction,
  OrderPatientMetaPropertiesSchema,
  OrderSwitzerlandPropertiesSchema,
  UserType,
} from 'interfaces/api';
import makeError from 'make-error';
import { OrderLocationState } from 'modules/orders';
import { faStethoscope, faUser, faUserCrown, faVial } from '@fortawesome/pro-regular-svg-icons';
import { drop, filter, get, keys, map, pick, reduce, set, without, zipObject } from 'lodash';
import { AxiosResponse } from 'axios';
import { PaginationResponse } from 'providers';
import { getCssVariableValue } from 'utils/dom';
import { AliasToken } from 'antd/es/theme/interface';
import { Color } from 'interfaces';
import { base64StringToBlob } from 'blob-util';
import dayjs from 'dayjs';

export function setChildrenProps(children: any, propsCreator: (child: any, idx: number) => any) {
  return map(React.Children.toArray(children), (child: any, idx: number) => {
    return React.cloneElement(child, {
      ...child.props,
      ...propsCreator(child, idx),
    });
  });
}

export function toggleArrayItem<T>(collection: T[], item: T, pickBy?: (item: T) => any, unshift?: boolean): T[] {
  const result = (collection || []).slice(0);
  const index: number = pickBy ? collection.map(pickBy).indexOf(pickBy(item)) : result.indexOf(item);

  if (index === -1) {
    unshift ? result.unshift(item) : result.push(item);
  } else {
    result.splice(index, 1);
  }

  return result;
}

export const splitEnum = (E: any) => {
  const keys = Object.keys(E).filter(k => typeof E[k as any] === 'number');
  const values = keys.map(k => E[k as any]);
  return { keys, values };
};

export const isStringEnum = (E: any) => filter(map(E, (v, k) => typeof v === 'string' && typeof k === 'string' && isNaN(parseInt(k)))).length === keys(E).length;

export const splitEnumOptions = (E: any, labels?: any) => {
  const enumIsStringEnum = isStringEnum(E);
  return map(enumIsStringEnum ? E : splitEnum(E).values, (value: any, k) => ({
    value, label: labels?.[E[enumIsStringEnum ? k : E[value]]] || (enumIsStringEnum ? k : E[value]),
  }));
};

/**
 * Returs Select options.
 * Value -> property name or property value if valuesOnly is true
 * Label -> property value
 * @param O
 * @param labelAsValue
 */
export const splitObjectOptions = (O: any, labelAsValue?: boolean) => map(keys(O), k => ({ value: labelAsValue ? O[k] : k, label: O[k] }));

export type Unpacked<P> = P extends (infer U)[] ? U : P extends object ? P : never;

export type AnyFunction = (...args: any[]) => any;

export const isFunction = <T extends AnyFunction>(value: any): value is T => typeof value === 'function';

export const isPaginatedResponse = <R, >(obj: PaginationResponse<R> | R[]): obj is PaginationResponse<R> => {
  return obj !== undefined && (obj as PaginationResponse<R>).results !== undefined;
};

/**
 * Takes a path (e.g. /users/:id) and params (e.g. {id: 1}) and returns /users/1
 * @param {string} path
 * @param params
 * @returns {string}
 */
export function createPath(path: string, params: any) {
  return pathToRegexp.compile(path)(params);
}

/**
 * Takes a location (e.g. /users/1) and tests it against a path (e.g. /users/:id)
 * @param {string} location
 * @param {string} path
 */
export function testPath(location: string, path: string) {
  if (!location || !path) {
    return false;
  }
  const re = pathToRegexp.pathToRegexp(path);
  return re.exec(location);
}

/**
 * Takes a location (e.g. /users/1) and a path (e.g. /users/:id) and returns params {id: 1} if matched
 * @param {string} location
 * @param {string} path
 * @returns {any}
 */
export function getPathParams(location: string, path: string) {
  if (!location || !path) {
    return null;
  }

  const keys: any = [];
  const re = pathToRegexp.pathToRegexp(path, keys);
  const params = re.exec(location);

  if (params) {
    return zipObject(keys.map((k: any) => k.name), drop(params));
  }

  return null;
}

export function arrayify<T>(arg?: T | T[]): T[] {
  return arg !== undefined ? (Array.isArray(arg) ? arg : [arg]) : [];
}

export const nl2br = (str: string) => {

  const newlineRegex = /(\r\n|\r|\n)/g;

  if (typeof str !== 'string') {
    return str;
  }

  return str.split(newlineRegex).map((line) => {
    if (line.match(newlineRegex)) {
      return '<br/>';
    }
    return line;
  }).join('');
};

export class PromiseCancelError extends makeError.BaseError {
}

export const getWizardStateFromGetParams = (params: any): OrderLocationState => {

  const lanr = params.lanr as string;
  const bsnr = params.bsnr as string;
  const updated_at = (params.updatedatum || params.updated_at) as string;
  const apgid = parseInt(params.apgid as string);
  const apgids = params.apgids?.split(',')?.map((i: string) => parseInt(i));
  const apid = parseInt(params.apid as string);
  const aid = parseInt((params.loadaisimport || params.aid) as string) || undefined;

  const patient = pick(params, without(keys(OrderPatientMetaPropertiesSchema), 'apgid'));
  const switzerland = pick(params, keys(OrderSwitzerlandPropertiesSchema));

  if (patient?.insuranceNumber) {
    patient.hasInsuranceNumber = true;
  }

  const newCase = params.newCase as string;
  const room = params.room as string;
  const bed = params.bed as string;
  const profile = params.profil as string;
  const insuranceName = params.insuranceName as string;
  const exttnr = params.exttnr as string;
  const clientname = params.clientname as string;
  const printerAliasName = params.PrinterAliasName as string;
  const aisIdentifier = params.aiskennung as string;

  const logs = [{ action: OrderChangeAction.Url, data: { url: window.location.href }, created_at: dayjs().toISOString() }];

  return {
    showWizard: {
      lanr,
      bsnr,
      apid,
      aid,
      updated_at,
      apgid,
      apgids,
      patient,
      switzerland,
      newCase,
      room,
      bed,
      profile,
      insuranceName,
      exttnr,
      clientname,
      logs,
      printerAliasName,
      aisIdentifier,
    },
  };

};

export const UserTypeIcon = {
  [UserType.PAT]: faUser,
  [UserType.LAB]: faVial,
  [UserType.LAU]: faVial,
  [UserType.ARZ]: faStethoscope,
  [UserType.ADM]: faUserCrown,
  [UserType.SAD]: faUserCrown,
};

export const transformResponse = async (response: AxiosResponse, blobExpected?: boolean) => {
  if (blobExpected) {
    if (response.data instanceof Blob) {
      const match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(response.headers['content-disposition']);
      const filename = match && match.length >= 2 ? match[1] : 'file';
      return new File([response.data], filename, { type: response.headers['content-type'] });
    } else {
      return new File([base64StringToBlob(response.data.buffer)], response.data.name, { type: response.data.type });
    }
  }
  return response.data;
};

export const rgbFromHex = (hex: string) => {
  const rgb = hex.replace('#', '').trim().split(/(.{2})/).filter(f => !!f);
  return {
    r: parseInt(rgb[0], 16),
    g: parseInt(rgb[1], 16),
    b: parseInt(rgb[2], 16),
  };
};

export const hexLuma = (hex: string) => {
  const { r, g, b } = rgbFromHex(hex);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const getAntTheme = (): Partial<AliasToken> => ({
  fontFamily: getCssVariableValue('--font-family'),
  colorPrimary: getCssVariableValue('--primary-color'),
  colorBorder: getCssVariableValue('--color-smoke'),
  colorBorderSecondary: getCssVariableValue('--border-color'),
  colorTextBase: getCssVariableValue('--text-color-emphasis'),
});

export const getLogLevelColor = (level: LogLevel) => ({
  [LogLevel.Error]: Color.Red,
  [LogLevel.Warn]: Color.Yellow,
  [LogLevel.Info]: Color.Green,
  [LogLevel.Debug]: Color.Blue,
}[level]);

export const jsToCSS = (JS: CSSProperties) => {
  return map(JS, (value, key) => key.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`) + ': ' + value + ';').join('');
};

export const intervalToSeconds = (value: number, interval: Interval): number => {
  switch (interval) {
    case Interval.Second:
      return value;
    case Interval.Minute:
      return value * 60;
    case Interval.Hour:
      return value * 60 * 60;
    case Interval.Day:
      return value * 60 * 60 * 24;
    case Interval.Week:
      return value * 60 * 60 * 24 * 7;
    case Interval.Month:
      return value * 60 * 60 * 24 * 30;
    case Interval.Year:
      return value * 60 * 60 * 24 * 365;
  }
};

export const secondsToInterval = (value: number, interval: Interval): number => {
  switch (interval) {
    case Interval.Second:
      return value;
    case Interval.Minute:
      return value / 60;
    case Interval.Hour:
      return value / 60 / 60;
    case Interval.Day:
      return value / 60 / 60 / 24;
    case Interval.Week:
      return value / 60 / 60 / 24 / 7;
    case Interval.Month:
      return value / 60 / 60 / 24 / 30;
    case Interval.Year:
      return value / 60 / 60 / 24 / 365;
  }
};

export const createRandomString = (): string => {
  return (Math.random() + 1).toString(36).substring(7);
};

export type ProfileGroups = Record<string, {
  name: string;
  profiles: [{
    id: number;
    name: string;
    costUnit: string;
    diagnosis: string;
    orderReason: string;
    selectedDiagnoses: Record<string, string>;
    type: AggregatedProfileType;
  }];
}>;

export const groupProfilesByCostUnit = (profiles: AggregatedProfileProperties[], hasRequirementDiagnosesFeature: boolean) => reduce(profiles, (acc, p) => {
  if (!get(acc, p.costUnit)) {
    set(acc, p.costUnit, { name: p.costUnit, profiles: [] });
  }

  acc[p.costUnit].profiles.push({
    id: p.entityId,
    name: p.name,
    costUnit: p.costUnit,
    diagnosis: p.diagnosis,
    orderReason: p.orderReason,
    selectedDiagnoses: hasRequirementDiagnosesFeature ? p.selectedDiagnoses : undefined,
    type: p.entityType,
  });

  return acc;
}, {} as ProfileGroups);

export const isEmptyValue = (value: any) => {
  return (value === undefined || value === null || !(value + '').length);
};

export const getLongDateFormat = () => {
  return dayjs(new Date()).localeData().longDateFormat('L');
};
