import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback, useGuard } from 'containers';
import { useApi } from 'providers';
import messages from 'messages';
import { ReportListContext } from 'modules/reports/index';
import { Feature, FilterProfileProperties, GeneralReportFilterType, ReportCharacteristic, ReportStatus } from 'interfaces/api';
import { Filter, Filters } from 'components';
import { CssColor, HexColor, I18nMessage } from 'interfaces';
import { ReportStatusExtendedFilter } from 'modules/reports/filters';
import { filter, map } from 'lodash';

export const ReportQuickStatusFilter: any = {
  preliminary: { status: ReportStatus.Preliminary, characteristic: undefined, pathological: undefined, reportType: undefined },
  partial: { status: ReportStatus.Partial, characteristic: undefined, pathological: undefined, reportType: GeneralReportFilterType.Chemical },
  finalNonPatho: { status: ReportStatus.Final, characteristic: ReportCharacteristic.NotPathological, pathological: false, reportType: GeneralReportFilterType.Chemical },
  finalPatho: { status: ReportStatus.Final, characteristic: undefined, pathological: true, reportType: GeneralReportFilterType.Chemical },
  finalBak: { status: ReportStatus.Final, characteristic: undefined, pathological: undefined, reportType: GeneralReportFilterType.Microbiological },
};

const Component = (props: ListLayoutArgs<ReportListContext>) => {

  const { context, bindings: { setContext } } = props;
  const filterLabels = messages.reports.filters;
  const [laboratoryGroupFilterItems, setLaboratoryGroupFilterItems] = useState<{ value: any; label: I18nMessage | string; flag?: CssColor }[]>([]);
  const guard = useGuard();
  const { laboratoryGroupFilters: { getLaboratoryGroupFilters } } = useApi();

  useEffect(() => {
    guard({ feature: Feature.LaboratoryGroupReportFilter }, () => {
      getLaboratoryGroupFilters()
        .then((resp) => {
          setLaboratoryGroupFilterItems(map(resp.filters, f => ({ value: f.group, label: f.name || f.group, flag: (f.color ? new HexColor(f.color) : null) })));
        });
    });
  }, []);

  const filters: Filter[] = useMemo(() => filter([
    ReportStatusExtendedFilter,
    laboratoryGroupFilterItems?.length > 0 && {
      label: filterLabels.laboratoryGroup,
      filter: laboratoryGroup => ({ laboratoryGroup }),
      items: laboratoryGroupFilterItems,
    } as Filter,
  ]), [laboratoryGroupFilterItems]);

  const onFilter = useCallback((value: FilterProfileProperties) => {
    setContext({ filters: { ...context.filters, ...value }, filterProfile: undefined });
  }, [setContext, context]);

  return (
    <Filters
      filters={filters}
      onFilter={onFilter}
      filterValues={context.filters}
    />
  );
};

export const FiltersSection: ListLayoutSideBarHiddenSectionCallback<ReportListContext> = ({ context, bindings: { setContext } }) => ({
  component: Component,
});
