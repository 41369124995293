import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { Feature, Gender, OrderPatientMetaProperties } from 'interfaces/api';
import { Container } from 'components';
import { Guard, useGuard } from 'containers';
import messages from 'messages';
import { splitEnumOptions } from 'utils/helpers';

export const PatientDataSwitzerlandForm: React.ComponentType<FormItemRenderProps<OrderPatientMetaProperties>> = (props) => {

  const { Input, DatePicker, Select, Switch, value, CountrySelect } = props;

  const guard = useGuard();
  const hasCountryCodeSelect = guard({ feature: Feature.CountryCodeSelect }, () => true);

  const personLabels = messages.general.person;
  const switzerlandLabels = messages.orders.additionalFields.patient.switzerland;

  return (
    <Container grid={'49%'}>

      <Input property={'firstName'} label={personLabels.firstName} maxLength={50} floating/>
      <Input property={'lastName'} label={personLabels.lastName} maxLength={50} floating/>

      <Input property={'secondName'} label={switzerlandLabels.secondName} maxLength={50} floating/>

      <DatePicker property={'birthday'} label={personLabels.birthday} floating withMask disableFuture/>

      <Select
        floating
        label={personLabels.sex}
        property={'gender'}
        options={splitEnumOptions(Gender, messages.general.gender)}
      />

      <Input property={'address'} label={personLabels.address} maxLength={150} floating/>
      <Input property={'houseNumber'} label={personLabels.houseNumber} maxLength={50} floating className={'oa-patient-house-number'}/>
      <Input property={'addressAdditional'} label={personLabels.addressAdditional} maxLength={50} floating/>

      {hasCountryCodeSelect
        ? (
          <CountrySelect property={'country'} label={personLabels.country} floating/>
        )
        : <Input property={'country'} label={personLabels.country} maxLength={5} floating/>
      }
      <Input property={'zipCode'} label={personLabels.zip} maxLength={10} floating/>
      <Input property={'city'} label={personLabels.city} maxLength={50} floating/>

      <Input property={'canton'} label={switzerlandLabels.kanton} maxLength={50} floating/>
      <Input property={'ahvNr'} label={switzerlandLabels.ahvNr} maxLength={50} floating/>

      <Container horizontal>
        <Switch property={'hasInsuranceNumber'}/>
        <Input property={'insuranceNumber'} label={personLabels.insuranceNumber} maxLength={50} disabled={!value.hasInsuranceNumber} floating/>
      </Container>

      <Input property={'aisNumber'} label={switzerlandLabels.aisNumber} maxLength={100} floating/>

      <Guard feature={Feature.OrderWizardShowPatientContact}>
        <Input property={'phone'} label={personLabels.phone} maxLength={100} floating/>
        <Input property={'email'} label={personLabels.email} maxLength={255} floating/>
      </Guard>

    </Container>
  );

};
