import { useOfficeDoctorContext, useOrderRulesContext, useOrdersContext, useWizardToggleContext } from 'modules/orders/providers';
import { Feature, FormType, OrderBasketFormProperties, OrderRuleConditionType, OrderSample, OrderWizardGetBasketResponse } from 'interfaces/api';
import { useLogger } from 'providers';
import { useGuard } from 'containers';
import { filter, find, includes, map, reduce, uniq } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';

export const useLoadBasket = (
  setFormGroups: Dispatch<SetStateAction<OrderBasketFormProperties[]>>,
  setOrderSamples: Dispatch<SetStateAction<OrderSample[]>>,
) => {

  const { orders, currentOrder, setOrder, setOrderProperties } = useOrdersContext();

  const { wizardSettings, officeDoctor } = useOfficeDoctorContext();
  const { setPending: setPendingRules, loading } = useOrderRulesContext();
  const basketRequirements = useBasketRequirements();

  const logger = useLogger('BasketProvider -> useLoadBasket');
  const toggle = useWizardToggleContext();

  const guard = useGuard();

  // load basket function
  const loadBasket = () => {

    if (officeDoctor?.aid) {
      new Promise<OrderWizardGetBasketResponse>((resolve, reject) => {
        setPendingRules({ resolve, reject, orders, aid: officeDoctor.aid });
      }).then((resp) => {
        setFormGroups(resp.basket);
        setOrderSamples(resp.samples);

        guard({ feature: Feature.QuickReport }, () => {
          // updates the printQuickReportBarcode property
          // (https://labuniq.atlassian.net/browse/CSM-124?focusedCommentId=43060)
          const printByRules = reduce(resp.rules, (acc, rule) => {
            return acc || !!find(rule.conditions, c => c.type === OrderRuleConditionType.Requirement)
              && (rule.printQuickReportBarcode || rule.printQuickReportBarcodeCovid);
          }, currentOrder.printQuickReportBarcode);

          const printQuickReportBarcode = reduce(resp.basket, (acc, item) => {
            return acc || !!find(item.requirements, r => includes([FormType.KBVMuster10C, FormType.KBVMusterOegd], r.form?.formType));
          }, printByRules);

          setOrderProperties({ printQuickReportBarcode });
        });

        const splitIndexes = uniq(map(resp.basket, b => b.splitIndex));
        const splitCount = splitIndexes.length;

        if (splitCount > 1) {
          const splitProperties = map(splitIndexes, (splitIndex, index) => currentOrder.splitProperties?.[splitIndex]
            ? currentOrder.splitProperties[splitIndex]
            : splitCount === 2 && index === 0 // special case: splitting is changed by load basket from single order to split order, or vice versa
              ? {
                diagnosis: currentOrder.selectedDiagnoses ? currentOrder.requirementDiagnosisFreeText : currentOrder.patient.diagnosis,
                freeText: currentOrder.freeText,
                anamnesticInfo: currentOrder.anamnesticInfo,
                externalOrderInfo: currentOrder.externalOrderInfo,
                printedMaterials: currentOrder.printedMaterials,
              }
              : {
                diagnosis: '',
                freeText: '',
                anamnesticInfo: '',
                externalOrderInfo: null,
                printedMaterials: null,
              });

          setOrder(o => ({
            ...o,
            splitProperties,
          }));
        }
      }).catch((error) => {
        logger.error(error);
      });
    } else {
      setFormGroups([]);
      setOrderSamples([]);
    }
  };

  // load basket effect
  useEffect(() => {
    if (toggle[0] && !loading && wizardSettings) {
      loadBasket();
    }
  }, [
    basketRequirements?.map(r => r.id + ',' + r.formId).join(','),
    filter(basketRequirements?.map(r => r.selectedAnalyses)).join(','),
    filter(basketRequirements?.map(r => r.freeText)).join(','),
    filter(basketRequirements?.map(r => r.freeTextDate)).join(','),
    filter(basketRequirements?.map(r => r.invoiceTo)).join(','),
    orders?.map(o => o.tnr || o.id).join(','),
    toggle[0],
    officeDoctor?.aid,
    uniq(orders?.map(o => o.costUnit)).join(','),
    wizardSettings,
  ]);

};
