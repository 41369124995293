import { useCallback, useMemo, useState } from 'react';
import { AggregatedProfileProperties, AggregatedSuperRequirementProperties, OrderBasketFormProperties, OrderSample, OrderWizardRequirement } from 'interfaces/api';
import constate from 'constate';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { useOfficeDoctorContext, useOrdersContext, useShowTextActions } from 'modules/orders/providers';
import { filter, find, flatten, groupBy, includes, map, uniq, uniqBy, without } from 'lodash';
import { useCostUnitCheck, useLoadBasket } from './effects';
import { useSetInvoiceTo } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/effects/useSetInvoiceTo';

const useBasket = () => {

  const [pending, setPending] = useState<{ requirement: OrderWizardRequirement; missing: MissingRequirementOption }>();

  const [showRules, setShowRules] = useState<OrderWizardRequirement[]>();

  const [formGroups, setFormGroups] = useState<OrderBasketFormProperties[]>([]);
  const [orderSamples, setOrderSamples] = useState<OrderSample[]>([]);

  const { wizardSettings } = useOfficeDoctorContext();

  const { orders, setAllOrdersProperties, currentOrder } = useOrdersContext();

  useShowTextActions();

  useLoadBasket(setFormGroups, setOrderSamples);
  useCostUnitCheck();

  const basketRequirements = useMemo(() => filter(flatten(orders.map(o => o.requirements))), [orders]);
  const uniqRequirements = useMemo(() => uniqBy(basketRequirements, r => r.id), [basketRequirements]);

  // unique forms in basket
  const uniqForms = useMemo(
    () => uniq(filter(basketRequirements.map(r => r.formId).map(id => find(wizardSettings?.forms, { id })))) || [],
    [basketRequirements, wizardSettings?.forms],
  );

  // unique form types in basket
  const uniqueFormTypes = useMemo(() => uniq(uniqForms.map(f => f.formType)), [uniqForms]);

  // get split forms
  const splitFormLabels: string[] = useMemo(
    () => map(groupBy(formGroups, g => g.splitIndex), groups => groups.map(g => find(wizardSettings?.forms, { id: g.formId })?.name).join(', ') + ' - ' + groups[0].tnr),
    [formGroups, wizardSettings?.forms],
  );

  // if invoice to is active
  const invoiceToActive = useMemo(
    () => uniqRequirements.filter(r => find(wizardSettings?.forms, { id: r.formId })?.invoiceToChangeable).length > 0,
    [uniqRequirements, wizardSettings?.forms],
  );

  useSetInvoiceTo(invoiceToActive);

  // all profiles
  const profiles = useMemo(
    () => filter(wizardSettings?.profiles, p => includes(uniq(without(map(uniqRequirements, r => r.profileId), null, undefined)), p.id)),
    [wizardSettings?.profiles, uniqRequirements],
  );

  // get count of requirement in basket
  const getBasketCount = useCallback(({ id }: OrderWizardRequirement): number => {
    return filter(basketRequirements, { id }).length;
  }, [basketRequirements]);

  // requirements in pool mode not for all patients
  const poolRequirementsPartialInBasket = useCallback((requirement: OrderWizardRequirement) => {
    const count = getBasketCount(requirement);
    return count > 0 && count < orders.length;
  }, [orders.length, getBasketCount]);

  // requirements in pool mode not for all patients
  const poolRequirementsAllInBasket = useCallback((requirement: OrderWizardRequirement) => {
    return currentOrder ? currentOrder.requirements.map(r => r.id).includes(requirement.id) : getBasketCount(requirement) === orders.length;
  }, [orders.length, basketRequirements, currentOrder, getBasketCount]);

  // function to check if profile requirements are partially in basket
  const profileRequirementsPartialInBasket = useCallback((requirement: AggregatedProfileProperties) => {
    const profile = find(wizardSettings?.profiles, { id: requirement.id });
    return (profile?.requirements || []).filter(getBasketCount).length > 0;
  }, [wizardSettings?.profiles, getBasketCount]);

  // function to check if all profile requirements are in basket
  const profileRequirementsAllInBasket = useCallback((requirement: AggregatedProfileProperties) => {
    const profile = find(wizardSettings?.profiles, { id: requirement.id });
    const all = profile?.requirements?.length;
    return all > 0 && (profile?.requirements || []).filter(getBasketCount).length === profile?.requirements?.length;
  }, [wizardSettings?.profiles, getBasketCount]);

  // function to check if sub requirements of a super requirement are partially in basket
  const subRequirementsPartialInBasket = useCallback((requirement: AggregatedSuperRequirementProperties) => {
    const superRequirement = find(wizardSettings?.superRequirements, { entityId: requirement.entityId });
    return (superRequirement?.requirements || []).filter(getBasketCount).length > 0;
  }, [wizardSettings?.superRequirements, getBasketCount]);

  // function to check if all sub requirements of a super requirement are in basket
  const subRequirementsAllInBasket = useCallback((requirement: AggregatedSuperRequirementProperties) => {
    const superRequirement = find(wizardSettings?.superRequirements, { entityId: requirement.entityId });
    const all = superRequirement?.requirements?.length;
    return all > 0 && (superRequirement?.requirements || []).filter(getBasketCount).length === superRequirement?.requirements?.length;
  }, [wizardSettings?.superRequirements, getBasketCount]);

  // function to check if requirement is already in basket as analyses or sub requirement
  const inBasketAsDuplicateRequirement = useCallback(({ id, laboratoryGroup, shortName }: OrderWizardRequirement) => {
    if (wizardSettings?.preferences.orderWizardSubRequirementsInBasket) {
      return basketRequirements.filter(r => r.id !== id && r.laboratoryGroup === laboratoryGroup && r.duplicateRequirements.includes(shortName));
    }
    return [];
  }, [wizardSettings?.preferences.orderWizardSubRequirementsInBasket, basketRequirements]);

  // clear basket function
  const clearBasket = useCallback(() => {
    setAllOrdersProperties({ requirements: [] });
  }, [setAllOrdersProperties]);

  return {
    basketRequirements,
    uniqRequirements,
    profiles,
    getBasketCount,
    profileRequirementsAllInBasket,
    profileRequirementsPartialInBasket,
    subRequirementsAllInBasket,
    subRequirementsPartialInBasket,
    poolRequirementsAllInBasket,
    poolRequirementsPartialInBasket,
    inBasketAsDuplicateRequirement,
    clearBasket,
    uniqForms,
    uniqueFormTypes,
    splitFormLabels,
    pending,
    setPending,
    showRules,
    setShowRules,
    formGroups,
    orderSamples,
    invoiceToActive,
  };

};

const [BasketProvider, useBasketContext] = constate(useBasket);

export {
  BasketProvider,
  useBasketContext,
};
