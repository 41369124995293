import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Control, HelgaContainer, Icon, Modal, ModalProps } from 'components';
import { Color } from 'interfaces';
import { FormNotFoundProperties, ProfileNameCollisionProperties, ProfileNameConfirmProperties } from 'interfaces/api';
import { useIntl } from 'providers';
import { faArrowRight, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import messages from 'messages';
import cx from 'classnames';
import './ConfirmCopyModal.less';
import { Col, Row } from 'antd';
import { filter, get, includes, join, map, reduce, set } from 'lodash';

export type ConfirmModalProps = ModalProps & {
  open: boolean;
  formNotFound: FormNotFoundProperties[];
  nameCollision: ProfileNameCollisionProperties[];
  next?: (confirmedNames?: ProfileNameConfirmProperties[]) => void;
  cancel?: () => void;
};

type ProfileNamesConfirmationState = ProfileNameCollisionProperties & { controlIndex: number };

export const ConfirmCopyModal = (props: ConfirmModalProps) => {
  const { open, cancel, next, formNotFound, nameCollision } = props;

  const { translate } = useIntl();

  const [confirmedNames, setConfirmedNames] = useState<ProfileNameConfirmProperties[]>([]);

  const costUnits: string[] = [];

  useEffect(() => {
    setConfirmedNames(map(nameCollision, nc => ({ ...nc, replace: false })));
  }, [nameCollision]);

  const groupedFormNotFounds = reduce(formNotFound, (acc, nf) => {
    if (!includes(costUnits, nf.costUnit)) {
      costUnits.push(nf.costUnit);
    }

    return set(acc, `${nf.costUnit}`, [...(get(acc, `${nf.costUnit}`, [])), nf]);
  }, [] as FormNotFoundProperties[]);

  const groupedNameCollisions = reduce(nameCollision, (acc, nc, ix) => {
    if (!includes(costUnits, nc.costUnit)) {
      costUnits.push(nc.costUnit);
    }

    return set(acc, `${nc.costUnit}`, [...(get(acc, `${nc.costUnit}`, [])), { ...nc, controlIndex: ix }]);
  }, {} as { [k: string]: ProfileNamesConfirmationState[] });

  const formsNotFoundContent = (costUnit: string) => {
    const formNotFounds = get(groupedFormNotFounds, costUnit);

    return formNotFounds?.length
      ? (
        <Container className={'confirm-item-container'}>
          <Container className={'icon-info-wrapper'}>
            <Container className={'item-icon-container'}>
              <Icon {...{ icon: faTriangleExclamation, color: Color.Yellow, className: 'item-icon' }} />
            </Container>
            <Container className={'item-info-container'}>
              <p className={'item-info'}>{translate(messages.orders.wizard.profiles.controls.copy.formNotFound.content)}:</p>
              <p className={'requirements'}>{join(map(formNotFounds, c => `${c.requirement.longName} (${c.requirement.shortName})`), ', ')}</p>
            </Container>
          </Container>
        </Container>
      )
      : undefined;
  };

  const profileNameCollisionsContent = (costUnit: string) => {
    const nameCollisionItem = get(groupedNameCollisions, costUnit)?.[0];

    return nameCollisionItem
      ? (
        <Container className={'confirm-item-container'}>
          <Container className={'icon-info-wrapper'}>
            <Container className={'item-icon-container'}>
              <Icon {...{ icon: faTriangleExclamation, color: Color.Yellow, className: 'item-icon' }} />
            </Container>
            <Container className={'item-info-container'}>
              <p className={'item-info'}>{translate(messages.orders.wizard.profiles.controls.copy.nameCollision.content)}</p>
              <p
                dangerouslySetInnerHTML={{ __html: translate(messages.orders.wizard.profiles.controls.copy.nameCollision.question, { newName: nameCollisionItem.newName }) }}
                className={'item-question'}
              />
            </Container>
          </Container>
          <Container className={'item-controls-container'}>
            <Button
              className={cx({ ['button-selected']: confirmedNames[nameCollisionItem.controlIndex]?.replace }, 'confirm-replace-button')}
              onClick={(e) => {
                setConfirmedNames(map(confirmedNames.slice(), (cn, ix) => ({ ...cn, replace: ix === nameCollisionItem.controlIndex ? true : cn.replace })));
              }}
            >
              {translate(messages.orders.wizard.profiles.controls.copy.nameCollision.controlLabels.replace)}
            </Button>
            <Button
              className={cx({ ['button-selected']: !confirmedNames[nameCollisionItem.controlIndex]?.replace }, 'confirm-replace-button')}
              onClick={(e) => {
                setConfirmedNames(map(confirmedNames.slice(), (cn, ix) => ({ ...cn, replace: ix === nameCollisionItem.controlIndex ? false : cn.replace })));
              }}
            >
              {translate(messages.orders.wizard.profiles.controls.copy.nameCollision.controlLabels.rename)}
            </Button>
          </Container>
        </Container>
      )
      : undefined;
  };

  const renderConfirmInfo = () => map(costUnits, (cu) => {
    return (
      <Row key={cu}>
        <Col key={2} span={24}>
          <Container className={'const-unit-group'}>
            <h3>Kostenträger: {cu}</h3>
          </Container>
        </Col>

        {formsNotFoundContent(cu)}
        {profileNameCollisionsContent(cu)}
      </Row>
    );
  });

  const modalContent = useMemo(() => (
    <HelgaContainer
      title={messages.general.confirm}
      className={cx('confirm-form-container')}
      buttons={filter([cancel && (
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
        />
      ), next && (
        <Control
          icon={faArrowRight}
          label={messages.general.next}
          onClick={() => next(confirmedNames)}
          button={{ type: 'primary' }}
        />
      )])}
    >
      {renderConfirmInfo()}
    </HelgaContainer>
  ), [groupedFormNotFounds, groupedNameCollisions]);

  return (
    <Modal
      open={open}
      // zIndex={1080}
      footer={null}
      children={modalContent}
    />
  );
};
