import React from 'react';
import { Store as ReduxStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { App, ErrorBoundary } from 'containers';
import { Store } from 'store';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { NetworkCheckProvider } from 'providers/NetworkCheckProvider';
import { Compose } from 'containers/Compose';
import { AuthProvider } from 'modules/auth/providers';
import { TopBarProvider } from 'containers/App/TopBar/useTopBar';
import { BarcodeProvider, BiometricsProvider, ConfigProvider, IntlProvider, PushNotificationProvider, SocketProvider, StorageProvider } from 'providers';
import messages from 'messages';
import { NotificationCountProvider } from 'modules/notifications/providers/Notifications';
import { AppUpdateProvider } from 'providers/AppUpdateProvider/AppUpdateProvider';
import { MaintenanceProvider } from 'providers/MaintenanceProvider';
import { EnvProvider } from 'providers/EnvProvider';
import { LanguageCode } from 'interfaces/api';

interface RootProps {
  store: ReduxStore<Store>;
  history: History;
  locale: LanguageCode;
}

export default ({ store, history, locale }: RootProps) => {

  return (
    <Compose
      components={[
        [ReduxProvider, { store }],
        [IntlProvider, {
          locale,
          ageInYearsMessage: messages.general.ageInYears,
          ageInMonthsMessage: messages.general.ageInMonths,
        }],
        EnvProvider,
        AppUpdateProvider,
        SocketProvider,
        NetworkCheckProvider,
        MaintenanceProvider,
        StorageProvider,
        BiometricsProvider,
        [ConnectedRouter, { history }],
        ErrorBoundary,
        AuthProvider,
        ConfigProvider,
        NotificationCountProvider,
        PushNotificationProvider,
        TopBarProvider,
        BarcodeProvider,
      ]}
    >
      <App/>
    </Compose>
  );
};
