import * as App from './store/index';
import * as Auth from 'modules/auth/store';
import { applyMiddleware, combineReducers, compose, createStore, Dispatch, Store as ReduxStore } from 'redux';
import { createBrowserHistory, createHashHistory, History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';

export const history = typeof (window as any).cordova !== 'undefined' ? createHashHistory() : createBrowserHistory();

export interface Store extends App.Store, Auth.Store {
  router: RouterState;
}

export type DispatchMethod = (...args: any[]) => (dispatch: Dispatch, getState: () => Store) => any;

const createRootReducer = (browserHistory: History) => combineReducers({
  router: connectRouter(browserHistory),
  ...App.Reducers,
  ...Auth.Reducers,
});

const middlewares = [
  ReduxThunk,
  App.legacyRoutesMiddleware,
];

if (import.meta.env.MODE === 'development') {
  const logger = createLogger({
    collapsed: (getState: any, action: any) => !action.error,
    predicate: (getState: any, action: any) => action.type.indexOf('status/') === -1,
  });

  middlewares.push(logger as any);
}

const finalCreateStore = compose(applyMiddleware(...middlewares))(createStore);

let store: ReduxStore<Store>;

export function getStore() {
  return store;
}

export function configureStore(initialState?: any): ReduxStore<Store> {
  store = finalCreateStore(createRootReducer(history), initialState) as any;
  return store;
}
