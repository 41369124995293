import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { DatePicker, useConfirmModal } from 'components';
import messages from 'messages';
import { useConfig, useIntl } from 'providers';
import { PromiseCancelError } from 'utils/helpers';
import { IntRange } from 'rc-picker/lib/interface';

const labels = messages.orders.controls.schedule;

export type ScheduleDateModalOptions = {
  onDone: (value: dayjs.Dayjs) => Promise<void>;
  defaultValue: dayjs.Dayjs;
  autoFocus?: boolean;
};

export const useScheduleDatePickerAttributes = () => {

  const { preferences: { ordersScheduleTimeEnabled, ordersScheduleTimeInterval: minuteStep, ordersScheduleTimeEnableDefault, ordersScheduleTimeDefault } } = useConfig();

  const format = useMemo(() => 'L' + (ordersScheduleTimeEnabled ? ' - HH:mm' : ''), [ordersScheduleTimeEnabled]);

  const showTime = useMemo(
    () => ordersScheduleTimeEnabled ? (ordersScheduleTimeEnableDefault ? { defaultValue: dayjs(ordersScheduleTimeDefault, 'HH:mm') } : true) : false,
    [ordersScheduleTimeEnabled, ordersScheduleTimeEnableDefault],
  );

  return {
    format,
    showTime,
    minuteStep: minuteStep as IntRange<1, 59>,
    disablePast: true,
  };

};

export const useScheduleDateModal = () => {

  const { translate } = useIntl();
  const confirmModal = useConfirmModal();

  const attributes = useScheduleDatePickerAttributes();

  let isLoading = false;

  return useCallback(({ autoFocus, onDone, defaultValue }: ScheduleDateModalOptions) => new Promise((resolve, reject) => {

    let scheduled_at = defaultValue;

    const modal = confirmModal({
      title: translate(labels.form.title),
      content: (
        <div>
          <DatePicker
            defaultValue={scheduled_at}
            onChange={(d) => {
              scheduled_at = d;
              modal.update({ okButtonProps: { disabled: !scheduled_at } });
            }}
            autoFocus={autoFocus}
            disabled={isLoading}
            placeholder={translate(labels.form.placeholder)}
            {...attributes}
          />
        </div>
      ),
      okButtonProps: { disabled: !scheduled_at },
      onOk: async () => {
        isLoading = true;
        await onDone(scheduled_at);
        isLoading = false;
        resolve(scheduled_at?.format());
      },
      onCancel: () => {
        reject(new PromiseCancelError());
      },
    });
  }), [confirmModal]);

};
